<template>
  <svg
    class="fill-current"
    width="19"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :d="
        props.outline
          ? 'M18.358 6.406a.75.75 0 0 1 .416 1.279L14.93 11.43l.907 5.29a.75.75 0 0 1-1.089.79l-4.75-2.497-4.75 2.497a.75.75 0 0 1-1.087-.79l.906-5.29-3.843-3.745a.75.75 0 0 1 .417-1.28l5.31-.771L9.328.825a.78.78 0 0 1 1.346 0l2.372 4.809 5.31.772h.002ZM10 2.85l2.05 4.154 4.587.667-3.319 3.234.784 4.57-4.103-2.157-4.103 2.157.783-4.57-3.32-3.234 4.588-.667L10 2.851ZM4.55 16.183s.001 0 0 0Z'
          : 'm18.357 6.406-5.311-.772L10.674.825a.78.78 0 0 0-1.346 0L6.951 5.634l-5.31.772a.75.75 0 0 0-.417 1.279l3.843 3.745-.906 5.29a.75.75 0 0 0 1.088.79l4.75-2.497 4.75 2.497a.751.751 0 0 0 1.088-.79l-.907-5.29 3.844-3.745a.75.75 0 0 0-.416-1.28h-.001Z'
      "
      fill-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts" setup>
interface SvgGlyphStarProps {
  outline?: boolean
}

const props = withDefaults(defineProps<SvgGlyphStarProps>(), { outline: false })
</script>
